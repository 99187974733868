<template>
  <div class="container">
    <div class="match-header" v-if="isMatchReady" :class="match.map">
      <div class="row">
        <div class="col-12 col-md-9">
          <div class="header-content">
            <div class="team-column" v-if="match.team_a && match.team_b">
              <div v-if="match.team_a" class="team">{{match.team_a.name}}</div>x
              <div v-if="match.team_b" class="team">{{match.team_b.name}}</div>
            </div>
            <p class="score">{{ match.team_a_score }}:{{ match.team_b_score }}</p>
            <p class="mt-0" v-html="match.map"></p>
            <p v-html="match.name || $t('UTILITY.unnamed') "></p>
            <p v-html="match.description"></p>
          </div>
        </div>

        <div class="col-12 col-md-3">
          <div class="header-sidebar">
            <b-btn v-b-modal.modalEditMatch
              v-if="isLoggedIn && match.has_admin_access"
              block>
              <i class="fas fa-edit"></i>
              {{ $t('MATCHES.actions.edit_match') }}
            </b-btn>

            <b-btn
              @click="destroyMatch"
              v-if="isLoggedIn && isAdmin"
              block
            >
              <i class="fas fa-trash"></i>
              {{ $t('MATCHES.actions.delete_match') }}
            </b-btn>

            <b-btn
              v-b-modal.modalCollection
              v-if="isLoggedIn && isPremium"
              block
            >
              <i class="fas fa-folder-plus"></i>
              {{ $t('MATCHES.actions.add_to_collection') }}
            </b-btn>

            <b-btn v-b-modal.modalShare v-if="isLoggedIn && match.has_admin_access && isPremium" block>
              <i class="fas fa-share"></i>
              {{ $t('MATCHES.actions.share_match') }}
            </b-btn>

            <a
              :href="match.demofile_url"
              target="_blank"
              class="btn btn-secondary btn-block"
            >
              <i class="fas fa-download"></i>
              {{ $t('MATCHES.actions.download_match') }}
            </a>

          </div>
        </div>
      </div>
    </div>

    <b-alert
      show
      variant="info"
      v-if="!isMatchReady"
    >
      <h4 class="alert-heading">{{ $t('MATCHES.parsing_alert.title') }}</h4>
      <p>{{ $t('MATCHES.parsing_alert.subtitle') }}</p>
    </b-alert>

    <div v-if="isMatchReady">



      <line-chart
      :chartdata="chartdata"
      :options="options"/>

















      <b-tabs content-class="mt-3" align="">
        <b-tab :title="$t('MATCHES.tabs.dashboard')" active>
          <!-- <h2>Visão Geral</h2> -->
          <div
            class="stats-table table-responsive table-scoreboard"
            v-if="match.scoreboard && match.scoreboard.players && match.scoreboard.players.length > 0"
          >
            <table class="table is-hoverable team1 mb-0">
              <thead>
                <tr>
                  <th>
                    <h4 class="mb-0">
                      <small>
                        {{ match.team_a_score}}
                      </small>
                      <router-link
                        :to="{ name: 'TeamSingle', params: { id: match.team_a.to_param }}"
                        v-if="match.team_a"
                      >{{ match.team_a.name }}</router-link>
                    </h4>
                  </th>
                  <th>
                    <abbr title="inimigos mortos">Kills</abbr>
                  </th>
                  <th>
                    <abbr title="vezes que você morreu">Deaths</abbr>
                  </th>
                  <th>
                    <abbr title="assistencias">Assist</abbr>
                  </th>
                  <!-- <th>
                    <abbr title="porcentagem de headshots">HS %</abbr>
                  </th>-->
                  <th>
                    <abbr title="inimigos mortos por round">KDR</abbr>
                  </th>
                  <!-- <th>
                    <abbr title="dano médio por round">ADR</abbr>
                  </th>
                  <th>
                    <abbr title="clutches">1vsX</abbr>
                  </th>
                  <th>
                    <abbr title="flashbang assists">BA</abbr>
                  </th>
                  <th>
                    <abbr title="matou 3 no round">3K</abbr>
                  </th>
                  <th>
                    <abbr title="matou 4 no round">4K</abbr>
                  </th>
                  <th>
                    <abbr title="matou o time adversário todo">ACE</abbr>
                  </th>
                  <th>
                    <abbr title="mortes que você vingou">Trades</abbr>
                  </th>-->
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="stat in match.scoreboard.players.filter(x => x.teamNumber == 2)"
                  :key="stat.steamId"
                >
                  <td>
                    <div v-if="stat.player">{{ stat.player.nickname }}</div>
                    <div v-else>{{ stat.steamId }}</div>
                  </td>
                  <td>{{ stat.kills }}</td>
                  <td>{{ stat.deaths }}</td>
                  <td>{{ stat.assists }}</td>
                  <!-- <td>{{ stat.headShotKills }}</td> -->
                  <td>{{ (stat.kills / stat.deaths).toFixed(2) }}</td>
                  <!-- <td>{{ stat.adr }}</td>
                  <td>{{ stat.clutches }}</td>
                  <td>{{ stat.flashbang_assists }}</td>
                  <td>{{ stat.k3 }}</td>
                  <td>{{ stat.k4 }}</td>
                  <td>{{ stat.k5 }}</td>
                  <td>{{ stat.tradekills }}</td>-->
                </tr>
              </tbody>
            </table>

            <div class="rounds-bar-horizontal">
              <div class="legend-round">
                <div class="line">T</div>
                <div class="number">-</div>
                <div class="line">CT</div>
              </div>
              <div
                :class="`round-column round-${round.round_number} ${round.winner} ${round.reason} ${round.round_number == 16 ? 'swap' : ''}`"
                v-for="round in match.match_rounds"
                :key="round.id"
              >
                <div class="swap-round" v-if="round.round_number == 16">
                  <div class="line">{{round.team_b_score}}</div>
                  <div class="number">swap</div>
                  <div class="line">{{round.team_a_score}}</div>
                </div>
                <router-link :to="{ name: 'RoundSingle', params: { id: round.id} }" class="round-cont">
                  <div class="line terrorists">
                    <span v-if="round.reason == 'reason_terrorist_win'">
                      <svg
                        width="16"
                        height="16"
                        fill="none"
                        id="resource-dead.svg"
                        name="dead"
                        color="red"
                      >
                        <path
                          d="M12.626 3.475C11.091 1.88 7.86 2 7.86 2s-3.071-.12-4.566 1.475C1.758 4.99 2 8.658 2 8.658s-.283 2.272 2.424 2.91c.849.359.849 1.156.849 1.156v.598c0 .36.283.678.646.678h3.96a.703.703 0 0 0 .687-.678v-.598s0-.797.889-1.156c2.787-.638 2.505-2.87 2.505-2.87s.242-3.708-1.334-5.223zm-6.95 5.621l-.282.04a1.06 1.06 0 0 1-1.05-.398l-.081-.08A1.168 1.168 0 0 1 4.1 7.62l.121-.358c.081-.2.243-.32.485-.32h1.9c.242 0 .444.16.565.36.121.278.08.597-.121.797l-.324.398a1.869 1.869 0 0 1-1.05.598zm3.072 1.994l-.162.12a.371.371 0 0 1-.445-.04l-.282-.28-.243.28a.314.314 0 0 1-.404.04l-.161-.12c-.162-.12-.202-.32-.081-.439l.404-.638a.34.34 0 0 1 .283-.16h.565c.121 0 .243.04.283.16l.404.638c0 .16-.04.359-.161.439zm2.868-2.472l-.08.08c-.283.319-.688.478-1.091.398l-.283-.04c-.404-.08-.808-.279-1.05-.558L8.747 8.1a.727.727 0 0 1-.122-.798.61.61 0 0 1 .566-.358h1.98c.202 0 .404.12.485.319l.12.358c.122.36.082.758-.16.997z"
                          fill="currentColor"
                        ></path>
                      </svg>
                    </span>
                    <span v-if="round.reason == 'reason_target_bombed'">
                      <svg
                        width="16"
                        height="16"
                        fill="none"
                        id="resource-explosion.svg"
                        name="explosion"
                        color="red"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M7.5 13L0 9h4.5L1.684 3.25 6.5 6l2-6 2 6 5.079-2.344L12.5 9H16l-6.5 4 2-2.5H10L11.5 8l-2 1-1-3-1 3-2-1.5 1.5 3H5L7.5 13zm5.032-11.54L13.86.24l-.444 1.822-1.238.855.355-1.457zM4.07 0l.332 2.478 1.401 1.427.019-2.121L4.07 0zM1.49 7.51L.193 6.258l1.844.337.927 1.187-1.475-.27z"
                          fill="currentColor"
                        ></path>
                      </svg>
                    </span>
                  </div>
                  <div class="number">{{round.round_number}}</div>
                  <div class="line cts">
                    <span v-if="round.reason == 'reason_ct_win' ">
                      <svg
                        width="16"
                        height="16"
                        fill="none"
                        id="resource-dead.svg"
                        name="dead"
                        color="blue"
                      >
                        <path
                          d="M12.626 3.475C11.091 1.88 7.86 2 7.86 2s-3.071-.12-4.566 1.475C1.758 4.99 2 8.658 2 8.658s-.283 2.272 2.424 2.91c.849.359.849 1.156.849 1.156v.598c0 .36.283.678.646.678h3.96a.703.703 0 0 0 .687-.678v-.598s0-.797.889-1.156c2.787-.638 2.505-2.87 2.505-2.87s.242-3.708-1.334-5.223zm-6.95 5.621l-.282.04a1.06 1.06 0 0 1-1.05-.398l-.081-.08A1.168 1.168 0 0 1 4.1 7.62l.121-.358c.081-.2.243-.32.485-.32h1.9c.242 0 .444.16.565.36.121.278.08.597-.121.797l-.324.398a1.869 1.869 0 0 1-1.05.598zm3.072 1.994l-.162.12a.371.371 0 0 1-.445-.04l-.282-.28-.243.28a.314.314 0 0 1-.404.04l-.161-.12c-.162-.12-.202-.32-.081-.439l.404-.638a.34.34 0 0 1 .283-.16h.565c.121 0 .243.04.283.16l.404.638c0 .16-.04.359-.161.439zm2.868-2.472l-.08.08c-.283.319-.688.478-1.091.398l-.283-.04c-.404-.08-.808-.279-1.05-.558L8.747 8.1a.727.727 0 0 1-.122-.798.61.61 0 0 1 .566-.358h1.98c.202 0 .404.12.485.319l.12.358c.122.36.082.758-.16.997z"
                          fill="currentColor"
                        ></path>
                      </svg>
                    </span>
                    <span v-if="round.reason == 'reason_target_saved'">
                      <svg
                        width="16"
                        height="16"
                        fill="none"
                        id="resource-time.svg"
                        name="time"
                        color="blue"
                      >
                        <mask id="time__a" fill="#fff">
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M12.714 8a4.714 4.714 0 1 1-9.429 0 4.714 4.714 0 0 1 9.43 0zM14 8A6 6 0 1 1 2 8a6 6 0 0 1 12 0zm-7.714.857h2.143l.305-.128.03-.033a.426.426 0 0 0 .093-.267V5.857a.429.429 0 0 0-.857 0V8H6.286a.429.429 0 1 0 0 .857z"
                          ></path>
                        </mask>
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M12.714 8a4.714 4.714 0 1 1-9.429 0 4.714 4.714 0 0 1 9.43 0zM14 8A6 6 0 1 1 2 8a6 6 0 0 1 12 0zm-7.714.857h2.143l.305-.128.03-.033a.426.426 0 0 0 .093-.267V5.857a.429.429 0 0 0-.857 0V8H6.286a.429.429 0 1 0 0 .857z"
                          fill="currentColor"
                        ></path>
                        <path
                          d="M8.429 8.857v1.5h.301l.278-.116-.58-1.384zm.305-.128l.58 1.384.276-.116.21-.213-1.066-1.055zm.03-.033l-1.171-.938-.002.003 1.172.935zM8 8v1.5h1.5V8H8zm0 6.214A6.214 6.214 0 0 0 14.214 8h-3A3.214 3.214 0 0 1 8 11.214v3zM1.786 8A6.214 6.214 0 0 0 8 14.214v-3A3.214 3.214 0 0 1 4.786 8h-3zM8 1.786A6.214 6.214 0 0 0 1.786 8h3A3.214 3.214 0 0 1 8 4.786v-3zM14.214 8A6.214 6.214 0 0 0 8 1.786v3A3.214 3.214 0 0 1 11.214 8h3zM8 15.5A7.5 7.5 0 0 0 15.5 8h-3A4.5 4.5 0 0 1 8 12.5v3zM.5 8A7.5 7.5 0 0 0 8 15.5v-3A4.5 4.5 0 0 1 3.5 8h-3zM8 .5A7.5 7.5 0 0 0 .5 8h3A4.5 4.5 0 0 1 8 3.5v-3zM15.5 8A7.5 7.5 0 0 0 8 .5v3A4.5 4.5 0 0 1 12.5 8h3zm-7.071-.643H6.286v3h2.143v-3zm-.274-.011l-.306.128 1.16 2.767.305-.128-1.16-2.767zm-.564.415c.024-.03.05-.06.076-.086l2.134 2.109c.047-.048.092-.099.135-.152L7.59 7.76zm-.234.668c0-.252.089-.488.236-.67l2.34 1.876c.265-.33.424-.752.424-1.206h-3zm0-2.572V8.43h3V5.857h-3zM8.43 6.93c-.592 0-1.072-.48-1.072-1.072h3A1.929 1.929 0 0 0 8.43 3.93v3zM9.5 5.857c0 .592-.48 1.072-1.071 1.072v-3A1.929 1.929 0 0 0 6.5 5.857h3zM9.5 8V5.857h-3V8h3zM6.286 9.5H8v-3H6.286v3zm1.071-1.072c0 .593-.48 1.072-1.071 1.072v-3a1.929 1.929 0 0 0-1.929 1.928h3zm-1.071-1.07c.592 0 1.071.48 1.071 1.07h-3c0 1.065.863 1.93 1.929 1.93v-3z"
                          fill="currentColor"
                          mask="url(#time__a)"
                        ></path>
                      </svg>
                    </span>
                    <span v-if="round.reason == 'reason_bomb_defused'">
                      <svg
                        width="14"
                        height="14"
                        fill="none"
                        id="resource-ct_win.svg"
                        name="ct_win"
                        color="blue"
                      >
                        <path
                          d="M5.084 1.147C3.826-.438 1.938-.042 1.151.355l.974 1.065c.393 0 .993.124 1.386.917.364.733 0 1.585-.393 2.378-.113.228.472.635.786 1.586 1.124 3.4 3.146 5.153 3.54 5.946.393.793-.348 1.602.786 1.586.845-.012.863-2.034-.423-3.535-2.131-2.486-2.33-4.79-2.33-5.583 0-.793.787-1.982-.393-3.568z"
                          fill="currentColor"
                        ></path>
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M2.847 3.551a1.43 1.43 0 0 1-.485-.124c-.805-.369-.947-.964-.959-1.357L.307 1.13c-.371.798-.709 2.698.915 3.906.565.393 1.074.55 1.531.586L2.738 5.6c-.068-.098-.16-.23-.22-.349-.06-.115-.228-.466-.037-.851.153-.31.282-.59.366-.849zm.762-.095c-.09.419-.287.847-.491 1.26-.053.106.048.253.203.477.074.108.16.233.248.38a6.54 6.54 0 0 0-.249-.38c-.155-.224-.256-.37-.203-.478.205-.412.401-.84.492-1.26zM6.24 5.443c1.096.208 2.607.737 4.213 2.03 1.54 1.238 3.562 1.158 3.547.312-.012-.7-.317-.676-.738-.641-.26.02-.564.045-.872-.095-.225-.103-.53-.332-.926-.63-1.016-.761-2.638-1.977-5.088-2.711-.03.175-.066.334-.097.47l-.005.023c-.06.27-.087.41-.087.514 0 .163.01.412.053.728z"
                          fill="currentColor"
                        ></path>
                      </svg>
                    </span>
                  </div>
                </router-link>
              </div>
            </div>

            <table class="table is-hoverable team2">
              <thead>
                <tr>
                  <th>
                    <h4 class="mb-0">
                      <small>
                        {{ match.team_b_score}}
                      </small>
                      <router-link
                        :to="{ name: 'TeamSingle', params: { id: match.team_b.to_param }}"
                        v-if="match.team_b"
                      >{{ match.team_b.name }}</router-link>
                    </h4>
                  </th>
                  <th>
                    <abbr title="inimigos mortos">Kills</abbr>
                  </th>
                  <th>
                    <abbr title="vezes que você morreu">Deaths</abbr>
                  </th>
                  <th>
                    <abbr title="assistencias">Assist</abbr>
                  </th>
                  <!-- <th>
                    <abbr title="porcentagem de headshots">HS %</abbr>
                  </th>-->
                  <th>
                    <abbr title="inimigos mortos por round">KDR</abbr>
                  </th>
                  <!-- <th>
                    <abbr title="dano médio por round">ADR</abbr>
                  </th>
                  <th>
                    <abbr title="clutches">1vsX</abbr>
                  </th>
                  <th>
                    <abbr title="flashbang assists">BA</abbr>
                  </th>
                  <th>
                    <abbr title="matou 3 no round">3K</abbr>
                  </th>
                  <th>
                    <abbr title="matou 4 no round">4K</abbr>
                  </th>
                  <th>
                    <abbr title="matou o time adversário todo">ACE</abbr>
                  </th>
                  <th>
                    <abbr title="mortes que você vingou">Trades</abbr>
                  </th>-->
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="stat in match.scoreboard.players.filter(x => x.teamNumber == 3)"
                  :key="stat.steamId"
                >
                  <td>
                    <div v-if="stat.player">{{ stat.player.nickname }}</div>
                    <div v-else>{{ stat.steamId }}</div>
                  </td>
                  <td>{{ stat.kills }}</td>
                  <td>{{ stat.deaths }}</td>
                  <td>{{ stat.assists }}</td>
                  <!-- <td>{{ stat.headShotKills }}</td> -->
                  <td>{{ (stat.kills / stat.deaths).toFixed(2) }}</td>
                  <!-- <td>{{ stat.adr }}</td>
                  <td>{{ stat.clutches }}</td>
                  <td>{{ stat.flashbang_assists }}</td>
                  <td>{{ stat.k3 }}</td>
                  <td>{{ stat.k4 }}</td>
                  <td>{{ stat.k5 }}</td>
                  <td>{{ stat.tradekills }}</td>-->
                </tr>
              </tbody>
            </table>
          </div>          

        </b-tab>
        
        <b-tab :title="$t('MATCHES.tabs.economy')">
          <!-- <h2>Economia</h2> -->




          <div class="stats-table table-responsive">
            <table class="table is-hoverable text-center">
              <thead>
                <tr>
                  <td colspan="4" align="right">
                    <router-link
                      :to="{ name: 'TeamSingle', params: { id: match.team_a.to_param }}"
                      v-if="match.team_a"
                    >{{ match.team_a.name }}</router-link>
                  </td>
                  <td></td>
                  <td colspan="4" align="left">
                    <router-link
                      :to="{ name: 'TeamSingle', params: { id: match.team_b.to_param }}"
                      v-if="match.team_b"
                    >{{ match.team_b.name }}</router-link>
                  </td>
                </tr>
              </thead>
              <thead>
                <tr>
                  <th>{{ $t('MATCHES.economy.total_spent') }}</th>
                  <!-- <th>equip value</th> -->
                  <!-- <th>money saved</th> -->
                  <th>{{ $t('MATCHES.economy.start_money') }}</th>
                  <th>
                    <i class="fas fa-dollar-sign"></i>
                  </th>
                  <th>
                    <i class="fas fa-trophy"></i></th>
                  </th>
                  <th>{{ $t('UTILITY.round') }}</th>
                  <th>
                    <i class="fas fa-trophy"></i></th>
                  </th>
                  <th>
                    <i class="fas fa-dollar-sign"></i>
                  </th>
                  <th>{{ $t('MATCHES.economy.start_money') }}</th>
                  <!-- <th>money saved</th> -->
                  <!-- <th>equip value</th> -->
                  <th>{{ $t('MATCHES.economy.total_spent') }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="round in match.economy.round" :key="round.id">
                  <td>${{round.team_a.cashSpendThisRound}}</td>
                  <!-- <td>{{round.team_a.equipmentValue}}</td> -->
                  <!-- <td>{{round.team_a.moneySaved}}</td> -->
                  <td>${{round.team_a.roundStartEquipmentValue}}</td>
                  <td>{{ friendlyType(round.round_type, round.side_trouble, round.team_a.teamSide) }}</td>
                  <!-- <td></td> -->
                  <td>
                    <span v-if="round.team_winner == 'team_a'">
                      <span v-if="round.reason == 'reason_terrorist_win'">
                        <svg
                          width="16"
                          height="16"
                          fill="none"
                          id="resource-dead.svg"
                          name="dead"
                          color="red"
                        >
                          <path
                            d="M12.626 3.475C11.091 1.88 7.86 2 7.86 2s-3.071-.12-4.566 1.475C1.758 4.99 2 8.658 2 8.658s-.283 2.272 2.424 2.91c.849.359.849 1.156.849 1.156v.598c0 .36.283.678.646.678h3.96a.703.703 0 0 0 .687-.678v-.598s0-.797.889-1.156c2.787-.638 2.505-2.87 2.505-2.87s.242-3.708-1.334-5.223zm-6.95 5.621l-.282.04a1.06 1.06 0 0 1-1.05-.398l-.081-.08A1.168 1.168 0 0 1 4.1 7.62l.121-.358c.081-.2.243-.32.485-.32h1.9c.242 0 .444.16.565.36.121.278.08.597-.121.797l-.324.398a1.869 1.869 0 0 1-1.05.598zm3.072 1.994l-.162.12a.371.371 0 0 1-.445-.04l-.282-.28-.243.28a.314.314 0 0 1-.404.04l-.161-.12c-.162-.12-.202-.32-.081-.439l.404-.638a.34.34 0 0 1 .283-.16h.565c.121 0 .243.04.283.16l.404.638c0 .16-.04.359-.161.439zm2.868-2.472l-.08.08c-.283.319-.688.478-1.091.398l-.283-.04c-.404-.08-.808-.279-1.05-.558L8.747 8.1a.727.727 0 0 1-.122-.798.61.61 0 0 1 .566-.358h1.98c.202 0 .404.12.485.319l.12.358c.122.36.082.758-.16.997z"
                            fill="currentColor"
                          ></path>
                        </svg>
                      </span>
                      <span v-if="round.reason == 'reason_target_bombed'">
                        <svg
                          width="16"
                          height="16"
                          fill="none"
                          id="resource-explosion.svg"
                          name="explosion"
                          color="red"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M7.5 13L0 9h4.5L1.684 3.25 6.5 6l2-6 2 6 5.079-2.344L12.5 9H16l-6.5 4 2-2.5H10L11.5 8l-2 1-1-3-1 3-2-1.5 1.5 3H5L7.5 13zm5.032-11.54L13.86.24l-.444 1.822-1.238.855.355-1.457zM4.07 0l.332 2.478 1.401 1.427.019-2.121L4.07 0zM1.49 7.51L.193 6.258l1.844.337.927 1.187-1.475-.27z"
                            fill="currentColor"
                          ></path>
                        </svg>
                      </span>
                      <span v-if="round.reason == 'reason_ct_win' ">
                        <svg
                          width="16"
                          height="16"
                          fill="none"
                          id="resource-dead.svg"
                          name="dead"
                          color="blue"
                        >
                          <path
                            d="M12.626 3.475C11.091 1.88 7.86 2 7.86 2s-3.071-.12-4.566 1.475C1.758 4.99 2 8.658 2 8.658s-.283 2.272 2.424 2.91c.849.359.849 1.156.849 1.156v.598c0 .36.283.678.646.678h3.96a.703.703 0 0 0 .687-.678v-.598s0-.797.889-1.156c2.787-.638 2.505-2.87 2.505-2.87s.242-3.708-1.334-5.223zm-6.95 5.621l-.282.04a1.06 1.06 0 0 1-1.05-.398l-.081-.08A1.168 1.168 0 0 1 4.1 7.62l.121-.358c.081-.2.243-.32.485-.32h1.9c.242 0 .444.16.565.36.121.278.08.597-.121.797l-.324.398a1.869 1.869 0 0 1-1.05.598zm3.072 1.994l-.162.12a.371.371 0 0 1-.445-.04l-.282-.28-.243.28a.314.314 0 0 1-.404.04l-.161-.12c-.162-.12-.202-.32-.081-.439l.404-.638a.34.34 0 0 1 .283-.16h.565c.121 0 .243.04.283.16l.404.638c0 .16-.04.359-.161.439zm2.868-2.472l-.08.08c-.283.319-.688.478-1.091.398l-.283-.04c-.404-.08-.808-.279-1.05-.558L8.747 8.1a.727.727 0 0 1-.122-.798.61.61 0 0 1 .566-.358h1.98c.202 0 .404.12.485.319l.12.358c.122.36.082.758-.16.997z"
                            fill="currentColor"
                          ></path>
                        </svg>
                      </span>
                      <span v-if="round.reason == 'reason_target_saved'">
                        <svg
                          width="16"
                          height="16"
                          fill="none"
                          id="resource-time.svg"
                          name="time"
                          color="blue"
                        >
                          <mask id="time__a" fill="#fff">
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M12.714 8a4.714 4.714 0 1 1-9.429 0 4.714 4.714 0 0 1 9.43 0zM14 8A6 6 0 1 1 2 8a6 6 0 0 1 12 0zm-7.714.857h2.143l.305-.128.03-.033a.426.426 0 0 0 .093-.267V5.857a.429.429 0 0 0-.857 0V8H6.286a.429.429 0 1 0 0 .857z"
                            ></path>
                          </mask>
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M12.714 8a4.714 4.714 0 1 1-9.429 0 4.714 4.714 0 0 1 9.43 0zM14 8A6 6 0 1 1 2 8a6 6 0 0 1 12 0zm-7.714.857h2.143l.305-.128.03-.033a.426.426 0 0 0 .093-.267V5.857a.429.429 0 0 0-.857 0V8H6.286a.429.429 0 1 0 0 .857z"
                            fill="currentColor"
                          ></path>
                          <path
                            d="M8.429 8.857v1.5h.301l.278-.116-.58-1.384zm.305-.128l.58 1.384.276-.116.21-.213-1.066-1.055zm.03-.033l-1.171-.938-.002.003 1.172.935zM8 8v1.5h1.5V8H8zm0 6.214A6.214 6.214 0 0 0 14.214 8h-3A3.214 3.214 0 0 1 8 11.214v3zM1.786 8A6.214 6.214 0 0 0 8 14.214v-3A3.214 3.214 0 0 1 4.786 8h-3zM8 1.786A6.214 6.214 0 0 0 1.786 8h3A3.214 3.214 0 0 1 8 4.786v-3zM14.214 8A6.214 6.214 0 0 0 8 1.786v3A3.214 3.214 0 0 1 11.214 8h3zM8 15.5A7.5 7.5 0 0 0 15.5 8h-3A4.5 4.5 0 0 1 8 12.5v3zM.5 8A7.5 7.5 0 0 0 8 15.5v-3A4.5 4.5 0 0 1 3.5 8h-3zM8 .5A7.5 7.5 0 0 0 .5 8h3A4.5 4.5 0 0 1 8 3.5v-3zM15.5 8A7.5 7.5 0 0 0 8 .5v3A4.5 4.5 0 0 1 12.5 8h3zm-7.071-.643H6.286v3h2.143v-3zm-.274-.011l-.306.128 1.16 2.767.305-.128-1.16-2.767zm-.564.415c.024-.03.05-.06.076-.086l2.134 2.109c.047-.048.092-.099.135-.152L7.59 7.76zm-.234.668c0-.252.089-.488.236-.67l2.34 1.876c.265-.33.424-.752.424-1.206h-3zm0-2.572V8.43h3V5.857h-3zM8.43 6.93c-.592 0-1.072-.48-1.072-1.072h3A1.929 1.929 0 0 0 8.43 3.93v3zM9.5 5.857c0 .592-.48 1.072-1.071 1.072v-3A1.929 1.929 0 0 0 6.5 5.857h3zM9.5 8V5.857h-3V8h3zM6.286 9.5H8v-3H6.286v3zm1.071-1.072c0 .593-.48 1.072-1.071 1.072v-3a1.929 1.929 0 0 0-1.929 1.928h3zm-1.071-1.07c.592 0 1.071.48 1.071 1.07h-3c0 1.065.863 1.93 1.929 1.93v-3z"
                            fill="currentColor"
                            mask="url(#time__a)"
                          ></path>
                        </svg>
                      </span>
                      <span v-if="round.reason == 'reason_bomb_defused'">
                        <svg
                          width="14"
                          height="14"
                          fill="none"
                          id="resource-ct_win.svg"
                          name="ct_win"
                          color="blue"
                        >
                          <path
                            d="M5.084 1.147C3.826-.438 1.938-.042 1.151.355l.974 1.065c.393 0 .993.124 1.386.917.364.733 0 1.585-.393 2.378-.113.228.472.635.786 1.586 1.124 3.4 3.146 5.153 3.54 5.946.393.793-.348 1.602.786 1.586.845-.012.863-2.034-.423-3.535-2.131-2.486-2.33-4.79-2.33-5.583 0-.793.787-1.982-.393-3.568z"
                            fill="currentColor"
                          ></path>
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M2.847 3.551a1.43 1.43 0 0 1-.485-.124c-.805-.369-.947-.964-.959-1.357L.307 1.13c-.371.798-.709 2.698.915 3.906.565.393 1.074.55 1.531.586L2.738 5.6c-.068-.098-.16-.23-.22-.349-.06-.115-.228-.466-.037-.851.153-.31.282-.59.366-.849zm.762-.095c-.09.419-.287.847-.491 1.26-.053.106.048.253.203.477.074.108.16.233.248.38a6.54 6.54 0 0 0-.249-.38c-.155-.224-.256-.37-.203-.478.205-.412.401-.84.492-1.26zM6.24 5.443c1.096.208 2.607.737 4.213 2.03 1.54 1.238 3.562 1.158 3.547.312-.012-.7-.317-.676-.738-.641-.26.02-.564.045-.872-.095-.225-.103-.53-.332-.926-.63-1.016-.761-2.638-1.977-5.088-2.711-.03.175-.066.334-.097.47l-.005.023c-.06.27-.087.41-.087.514 0 .163.01.412.053.728z"
                            fill="currentColor"
                          ></path>
                        </svg>
                      </span>
                    </span>
                  </td>
                  <td class="text-center">
                    <router-link :to="{ name: 'RoundSingle', params: { id: round.id} }">
                      {{round.round_number}}
                    </router-link>
                  </td>
                  <td>
                    <span v-if="round.team_winner == 'team_b'">
                      
                      <span v-if="round.reason == 'reason_terrorist_win'">
                        <svg
                          width="16"
                          height="16"
                          fill="none"
                          id="resource-dead.svg"
                          name="dead"
                          color="red"
                        >
                          <path
                            d="M12.626 3.475C11.091 1.88 7.86 2 7.86 2s-3.071-.12-4.566 1.475C1.758 4.99 2 8.658 2 8.658s-.283 2.272 2.424 2.91c.849.359.849 1.156.849 1.156v.598c0 .36.283.678.646.678h3.96a.703.703 0 0 0 .687-.678v-.598s0-.797.889-1.156c2.787-.638 2.505-2.87 2.505-2.87s.242-3.708-1.334-5.223zm-6.95 5.621l-.282.04a1.06 1.06 0 0 1-1.05-.398l-.081-.08A1.168 1.168 0 0 1 4.1 7.62l.121-.358c.081-.2.243-.32.485-.32h1.9c.242 0 .444.16.565.36.121.278.08.597-.121.797l-.324.398a1.869 1.869 0 0 1-1.05.598zm3.072 1.994l-.162.12a.371.371 0 0 1-.445-.04l-.282-.28-.243.28a.314.314 0 0 1-.404.04l-.161-.12c-.162-.12-.202-.32-.081-.439l.404-.638a.34.34 0 0 1 .283-.16h.565c.121 0 .243.04.283.16l.404.638c0 .16-.04.359-.161.439zm2.868-2.472l-.08.08c-.283.319-.688.478-1.091.398l-.283-.04c-.404-.08-.808-.279-1.05-.558L8.747 8.1a.727.727 0 0 1-.122-.798.61.61 0 0 1 .566-.358h1.98c.202 0 .404.12.485.319l.12.358c.122.36.082.758-.16.997z"
                            fill="currentColor"
                          ></path>
                        </svg>
                      </span>
                      <span v-if="round.reason == 'reason_target_bombed'">
                        <svg
                          width="16"
                          height="16"
                          fill="none"
                          id="resource-explosion.svg"
                          name="explosion"
                          color="red"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M7.5 13L0 9h4.5L1.684 3.25 6.5 6l2-6 2 6 5.079-2.344L12.5 9H16l-6.5 4 2-2.5H10L11.5 8l-2 1-1-3-1 3-2-1.5 1.5 3H5L7.5 13zm5.032-11.54L13.86.24l-.444 1.822-1.238.855.355-1.457zM4.07 0l.332 2.478 1.401 1.427.019-2.121L4.07 0zM1.49 7.51L.193 6.258l1.844.337.927 1.187-1.475-.27z"
                            fill="currentColor"
                          ></path>
                        </svg>
                      </span>
                      <span v-if="round.reason == 'reason_ct_win' ">
                        <svg
                          width="16"
                          height="16"
                          fill="none"
                          id="resource-dead.svg"
                          name="dead"
                          color="blue"
                        >
                          <path
                            d="M12.626 3.475C11.091 1.88 7.86 2 7.86 2s-3.071-.12-4.566 1.475C1.758 4.99 2 8.658 2 8.658s-.283 2.272 2.424 2.91c.849.359.849 1.156.849 1.156v.598c0 .36.283.678.646.678h3.96a.703.703 0 0 0 .687-.678v-.598s0-.797.889-1.156c2.787-.638 2.505-2.87 2.505-2.87s.242-3.708-1.334-5.223zm-6.95 5.621l-.282.04a1.06 1.06 0 0 1-1.05-.398l-.081-.08A1.168 1.168 0 0 1 4.1 7.62l.121-.358c.081-.2.243-.32.485-.32h1.9c.242 0 .444.16.565.36.121.278.08.597-.121.797l-.324.398a1.869 1.869 0 0 1-1.05.598zm3.072 1.994l-.162.12a.371.371 0 0 1-.445-.04l-.282-.28-.243.28a.314.314 0 0 1-.404.04l-.161-.12c-.162-.12-.202-.32-.081-.439l.404-.638a.34.34 0 0 1 .283-.16h.565c.121 0 .243.04.283.16l.404.638c0 .16-.04.359-.161.439zm2.868-2.472l-.08.08c-.283.319-.688.478-1.091.398l-.283-.04c-.404-.08-.808-.279-1.05-.558L8.747 8.1a.727.727 0 0 1-.122-.798.61.61 0 0 1 .566-.358h1.98c.202 0 .404.12.485.319l.12.358c.122.36.082.758-.16.997z"
                            fill="currentColor"
                          ></path>
                        </svg>
                      </span>
                      <span v-if="round.reason == 'reason_target_saved'">
                        <svg
                          width="16"
                          height="16"
                          fill="none"
                          id="resource-time.svg"
                          name="time"
                          color="blue"
                        >
                          <mask id="time__a" fill="#fff">
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M12.714 8a4.714 4.714 0 1 1-9.429 0 4.714 4.714 0 0 1 9.43 0zM14 8A6 6 0 1 1 2 8a6 6 0 0 1 12 0zm-7.714.857h2.143l.305-.128.03-.033a.426.426 0 0 0 .093-.267V5.857a.429.429 0 0 0-.857 0V8H6.286a.429.429 0 1 0 0 .857z"
                            ></path>
                          </mask>
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M12.714 8a4.714 4.714 0 1 1-9.429 0 4.714 4.714 0 0 1 9.43 0zM14 8A6 6 0 1 1 2 8a6 6 0 0 1 12 0zm-7.714.857h2.143l.305-.128.03-.033a.426.426 0 0 0 .093-.267V5.857a.429.429 0 0 0-.857 0V8H6.286a.429.429 0 1 0 0 .857z"
                            fill="currentColor"
                          ></path>
                          <path
                            d="M8.429 8.857v1.5h.301l.278-.116-.58-1.384zm.305-.128l.58 1.384.276-.116.21-.213-1.066-1.055zm.03-.033l-1.171-.938-.002.003 1.172.935zM8 8v1.5h1.5V8H8zm0 6.214A6.214 6.214 0 0 0 14.214 8h-3A3.214 3.214 0 0 1 8 11.214v3zM1.786 8A6.214 6.214 0 0 0 8 14.214v-3A3.214 3.214 0 0 1 4.786 8h-3zM8 1.786A6.214 6.214 0 0 0 1.786 8h3A3.214 3.214 0 0 1 8 4.786v-3zM14.214 8A6.214 6.214 0 0 0 8 1.786v3A3.214 3.214 0 0 1 11.214 8h3zM8 15.5A7.5 7.5 0 0 0 15.5 8h-3A4.5 4.5 0 0 1 8 12.5v3zM.5 8A7.5 7.5 0 0 0 8 15.5v-3A4.5 4.5 0 0 1 3.5 8h-3zM8 .5A7.5 7.5 0 0 0 .5 8h3A4.5 4.5 0 0 1 8 3.5v-3zM15.5 8A7.5 7.5 0 0 0 8 .5v3A4.5 4.5 0 0 1 12.5 8h3zm-7.071-.643H6.286v3h2.143v-3zm-.274-.011l-.306.128 1.16 2.767.305-.128-1.16-2.767zm-.564.415c.024-.03.05-.06.076-.086l2.134 2.109c.047-.048.092-.099.135-.152L7.59 7.76zm-.234.668c0-.252.089-.488.236-.67l2.34 1.876c.265-.33.424-.752.424-1.206h-3zm0-2.572V8.43h3V5.857h-3zM8.43 6.93c-.592 0-1.072-.48-1.072-1.072h3A1.929 1.929 0 0 0 8.43 3.93v3zM9.5 5.857c0 .592-.48 1.072-1.071 1.072v-3A1.929 1.929 0 0 0 6.5 5.857h3zM9.5 8V5.857h-3V8h3zM6.286 9.5H8v-3H6.286v3zm1.071-1.072c0 .593-.48 1.072-1.071 1.072v-3a1.929 1.929 0 0 0-1.929 1.928h3zm-1.071-1.07c.592 0 1.071.48 1.071 1.07h-3c0 1.065.863 1.93 1.929 1.93v-3z"
                            fill="currentColor"
                            mask="url(#time__a)"
                          ></path>
                        </svg>
                      </span>
                      <span v-if="round.reason == 'reason_bomb_defused'">
                        <svg
                          width="14"
                          height="14"
                          fill="none"
                          id="resource-ct_win.svg"
                          name="ct_win"
                          color="blue"
                        >
                          <path
                            d="M5.084 1.147C3.826-.438 1.938-.042 1.151.355l.974 1.065c.393 0 .993.124 1.386.917.364.733 0 1.585-.393 2.378-.113.228.472.635.786 1.586 1.124 3.4 3.146 5.153 3.54 5.946.393.793-.348 1.602.786 1.586.845-.012.863-2.034-.423-3.535-2.131-2.486-2.33-4.79-2.33-5.583 0-.793.787-1.982-.393-3.568z"
                            fill="currentColor"
                          ></path>
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M2.847 3.551a1.43 1.43 0 0 1-.485-.124c-.805-.369-.947-.964-.959-1.357L.307 1.13c-.371.798-.709 2.698.915 3.906.565.393 1.074.55 1.531.586L2.738 5.6c-.068-.098-.16-.23-.22-.349-.06-.115-.228-.466-.037-.851.153-.31.282-.59.366-.849zm.762-.095c-.09.419-.287.847-.491 1.26-.053.106.048.253.203.477.074.108.16.233.248.38a6.54 6.54 0 0 0-.249-.38c-.155-.224-.256-.37-.203-.478.205-.412.401-.84.492-1.26zM6.24 5.443c1.096.208 2.607.737 4.213 2.03 1.54 1.238 3.562 1.158 3.547.312-.012-.7-.317-.676-.738-.641-.26.02-.564.045-.872-.095-.225-.103-.53-.332-.926-.63-1.016-.761-2.638-1.977-5.088-2.711-.03.175-.066.334-.097.47l-.005.023c-.06.27-.087.41-.087.514 0 .163.01.412.053.728z"
                            fill="currentColor"
                          ></path>
                        </svg>
                      </span>
                    </span>
                  </td>
                  <td>{{ friendlyType(round.round_type, round.side_trouble, round.team_b.teamSide) }}</td>
                  <td>${{round.team_b.roundStartEquipmentValue}}</td>
                  <!-- <td>{{round.team_a.moneySaved}}</td> -->
                  <!-- <td>{{round.team_a.equipmentValue}}</td> -->
                  <td>${{round.team_b.cashSpendThisRound}}</td>
                  <!-- <td></td> -->
                </tr>
              </tbody>
            </table>
            <!-- kills / assist /  deaths/ k-d / kdr / kpr / adr / aces / mvps -->
          </div>


          <h1>{{ $t('MATCHES.economy.economy_vs_rounds') }}</h1>

          <div class="row">
            <div class="col col-md-6">
              <h4>{{ $t('UTILITY.team_a') }}</h4>
              <apexchart ref="team_a_chart" width="500" type="area" :options="chartOptions" :series="series"></apexchart>
            </div>
            <div class="col col-md-6">
              <h4>{{ $t('UTILITY.team_b') }}</h4>
              <apexchart ref="team_b_chart" width="500" type="area" :options="chartOptions" :series="series"></apexchart>
              <!-- <apexchart width="500" type="area" :options="chartOptions" :series="series"></apexchart> -->
            </div>
          </div>

        </b-tab>

        <b-tab :title="$t('MATCHES.tabs.watch')">
          <!-- <h2>Round a Round</h2> -->
          <div class="stats-table table-responsive">
            <table class="table is-hoverable">
              <thead>
                <tr>
                  <th style="width: 50px">{{ $t('UTILITY.round') }}</th>
                  <th style="width: 200px">{{ $t('UTILITY.winner') }}</th>
                  <th>{{ $t('UTILITY.timeline') }}</th>
                  <th>{{ $t('UTILITY.tags') }}</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="round in match.match_rounds" :key="round.id">
                  <td>
                    <div class="round-single">
                      <router-link :to="{ name: 'RoundSingle', params: { id: round.id }}">
                        <h4 v-html="round.round_number || '-' "></h4>
                      </router-link>
                    </div>
                  </td>
                  <td>
                    {{ friendlyWinner(round.winner) }}
                    <br>
                    {{ friendlyReason(round.reason) }}
                  </td>
                  <td class="timeline-column">
                    <router-link :to="{ name: 'RoundSingle', params: { id: round.id }}">
                      <div
                        class="timeline-container"
                        :data-startTick="round.round_start_tick"
                        :data-endTick="round.round_end_tick"
                      >
                        <div
                          class="timeline-events"
                          v-if="round.round_important_events.length < 20 && round.round_important_events.length > 0"
                        >
                          <div
                            :style="`left: ${calculateTimelineEventLeft(round.round_start_tick, round.round_end_tick, event.tick)}px`"
                            class="event"
                            v-for="event in round.round_important_events"
                            :key="event.id"
                          >
                            <span
                              :class="event.event_name"
                              v-b-tooltip.hover
                              :title="convertTickToRoundSeconds(round.round_start_tick, round.round_end_tick, event.tick, match.demofile_ticks_per_second)"
                            >
                              <i class="fas fa-skull" v-if="event.event_name == 'player_death'"></i>
                              <i class="fas fa-bomb" v-if="event.event_name == 'bomb_planted'"></i>
                            </span>
                            <!-- calculateTimelineEventLeft(startTick, endTick, eventTick) { -->
                          </div>
                        </div>
                      </div>
                    </router-link>
                  </td>

                  <td>
                    <b-badge size="sm" variant="dark" v-if="round.round_type">
                      {{ round.round_type.replace('round_', '') }}
                      <span
                        v-if="round.side_trouble && ['round_force_buy', 'round_semi_eco', 'round_eco'].includes(round.round_type)"
                      >{{ round.side_trouble.replace('trouble_', '') }}</span>
                    </b-badge>
                    <div class="tag-list" v-if="round.tag_list && round.tag_list.length > 0">
                      <b-badge variant="light" v-for="tag in round.tag_list" :key="tag" v-html="tag"></b-badge>
                    </div>
                    <i
                      v-b-tooltip.hover
                      title="editar tags"
                      @click="openTagsModal('MatchRound', round.id, round.tag_list)"
                      class="fas fa-pencil-alt"
                      v-if="isLoggedIn && isAdmin"
                    ></i>
                  </td>
                  <td>
                    <!--<i
                      v-b-tooltip.hover
                      v-b-modal.modalCollection
                      :title="$t('SEARCH.collection_modal.title')"
                      class="fas fa-folder-plus"
                      v-if="isLoggedIn && isAdmin"
                    ></i>-->
                  </td>

                  <!-- <td>{{ round.round_file_url }}</td> -->
                </tr>
              </tbody>
            </table>
          </div>
        </b-tab>
        
      </b-tabs>
    </div>

    <!-- modals -->
    <b-modal id="modalShare" ref="modalShare" :title="$t('MATCHES.share_modal.title')" :hide-footer="true">
      <form @submit.stop.prevent="handleShareSubmit">
        <b-form-input
          type="text"
          :placeholder="$t('MATCHES.share_modal.email_placeholder')"
          v-model="share.email"
        ></b-form-input>

        <b-btn type="submit" variant="primary">
          {{ $t('MATCHES.share_modal.submit') }}
        </b-btn>
      </form>
    </b-modal>

    <b-modal
      id="modalCollection"
      ref="modalCollection"
      :hide-footer="true"
      :hide-header="true"
    >
      <CollectionModal :favorable_id="match.id" favorable_type="Match"></CollectionModal>
    </b-modal>

    <b-modal id="modalTags" ref="modalTags" :title="$t('MATCHES.tags_modal.title')" :hide-footer="true">
      <form @submit.stop.prevent="handleTagsSubmit">
        <div class="intro"></div>

        <vue-tags-input v-model="tag_list" :tags="tags" @tags-changed="newTags => tags = newTags"/>

        <div class="tag-list">
          <h6>{{ $t('MATCHES.tags_modal.common_tags') }}</h6>
          <b-badge
            variant="light"
            @click="addTag(tag.name)"
            v-for="tag in commonTags"
            :key="tag.id"
          >{{tag.name}}</b-badge>
        </div>

        <b-btn type="submit" variant="primary">
          {{ $t('MATCHES.tags_modal.submit') }}
        </b-btn>
      </form>
    </b-modal>

    <b-modal id="modalEditMatch" ref="modalEditMatch" :title="$t('MATCHES.edit_modal.title')" :hide-footer="true">
      <form @submit.stop.prevent="handleEditSubmit">
        <b-form-input
          type="text"
          :placeholder="$t('MATCHES.edit_modal.name_placeholder')"
          v-model="match.name"
        ></b-form-input>

        <b-form-input type="text" placeholder="descricao" v-model="match.description"></b-form-input>

        <div v-if="currentUser.role == 'role_user_admin'">
          <b-form-input
            type="text"
            :placeholder="$t('MATCHES.edit_modal.unique_code_placeholder')"
            v-model="match.unique_code"
          ></b-form-input>

          <b-form-select
            id="teamA"
            :placeholder="$t('UTILITY.team_a')"
            :options="teams"
            value-field="id"
            text-field="name"
            required
            v-model="match.team_a_id"
          ></b-form-select>

          <b-form-select
            id="teamB"
            :placeholder="$t('UTILITY.team_b')"
            :options="teams"
            value-field="id"
            text-field="name"
            required
            v-model="match.team_b_id"
          ></b-form-select>
          <b-form-checkbox v-model="match.is_public" name="check-button" button>
            {{ $t('MATCHES.edit_modal.is_public_label') }}
          </b-form-checkbox>
        </div>

        <b-btn type="submit" variant="primary">
          {{ $t('MATCHES.edit_modal.submit') }}
        </b-btn>
      </form>
    </b-modal>

    
  </div>
</template>

<script>
import VueTagsInput from "@johmun/vue-tags-input";
import CollectionModal from "@/components/CollectionModal";
import LineChart from '@/components/Chart.vue'

export default {
  name: "MatchDetails",
  computed: {
    isPremium: function() {
      return (
        this.currentUser &&
        this.currentUser.subscription_package &&
        this.currentUser.subscription_package.package != "free"
      );
    },
    isMatchReady: function () {
      return this.match && !['demofile_state_parsing', 'demofile_state_waiting'].includes(this.match.demofile_state);
    }
  },
  data() {
    return {
      match: {},
      share: { email: "" },
      collections: [],
      teams: [],
      tag_list: "",
      tags: [],
      commonTags: [],

      loaded: false,
      chartdata: null,
      options: {},

      round_to_tag: "",
      team_a_score: [],
      team_b_score: [],
      team_a_money: [],
      team_b_money: [],
      chartOptions: {
        chart: {
          id: 'vuechart-example'
        },
        xaxis: {
          categories: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30]
        },
        yaxis: {
          categories: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30]
        },
        dataLabels: {
            enabled: false
          },
          stroke: {
            curve: 'smooth'
          }
      },
      series: [{
        name: 'rounds ganhos',
        data: [30, 40, 35, 50, 49, 60, 70, 91, 40, 35, 50, 49, 60, 70, 91]
      }
      , {
        name: 'economia $ gasto',
        data: [11, 32, 45, 32, 34, 52, 41, 88, 32, 45, 32, 34, 52, 41, 88]
      }
      ]
    };
  },
  methods: {
    initialize() {
      var self = this;
      this.$http
        .get("matches/" + this.$route.params.id + ".json")
        .then(response => {
          this.match = response.data;

          self.team_a_score = response.data.economy.round.map(r => r.team_a_score);
          self.team_b_score = response.data.economy.round.map(r => r.team_b_score);

          self.team_a_money = response.data.economy.round.map(r => r.team_a.cashSpendThisRound / 1000);
          self.team_b_money = response.data.economy.round.map(r => r.team_b.cashSpendThisRound / 1000);

          setTimeout(() => {
            self.$refs.team_a_chart.updateSeries([{
              data: self.team_a_score
            },{
              data: self.team_a_money
            }])

            self.$refs.team_b_chart.updateSeries([{
              data: self.team_b_score
            },{
              data: self.team_b_money
            }])
          }, 1000);


          this.chartdata = {
            labels: response.data.evolution.map((rnd, idx) => { return `round ${idx+1}` }),
            datasets: [
            ]
          }

          this.options = {
            responsive: true,
            maintainAspectRatio: false,
            onClick: function(evt, selectedRoundData, a) {
              console.log(a, selectedRoundData);

            }
          }
        //   scales: {
        //     yAxes: [{
        //         stacked: true
        //     }]
        // }

          response.data.evolution[0].forEach(player => {
            var obj = response.data.evolution.map((rnd, idx) => {
              var pl = rnd.find(el => { return el.steamId == player.steamId }) || { kills: 0 }
              return { x: idx + 1, y: pl.kills }
            });
            this.chartdata.datasets.push({
              label: player.nickname,
              fill: false,
              borderColor: player.teamNumber == 2 ? 'rgba(255, 0, 0, .1)' : 'rgba(0, 0, 255, .1)',
              data: obj
            })
          })

          // response.data.evolution.forEach((rnd, idx, arr) => {
          //   rnd.forEach((player, i, arrr) => {
          //     this.chartdata.datasets.push({
          //       label: player.steamId,
          //       data: round.map(el => { return { x: e, y: el.kills } })
          //     })  
          //   })
          //   console.log(rnd);
          //   this.chartdata.datasets.push({
          //     label: rnd.
          //     data: round.map(el => { return { x: e, y: el.kills } })
          //   })
            
          // })

        });

      this.$http.get("teams.json").then(response => {
        this.teams = response.data;
        this.teams.push({ id: null, name: "em branco" });
      });

      if (this.isLoggedIn)
        this.$http
          .get("collections.json")
          .then(response => (this.collections = response.data));

      this.$http
        .get("common_tags.json")
        .then(response => (this.commonTags = response.data));
    },

    calculateTimelineEventLeft(startTick, endTick, eventTick) {
      var timelinePx = 400;
      var totalTicks = endTick - startTick;

      var tickPx = timelinePx / totalTicks;
      var left = (eventTick - startTick) * tickPx;
      return left - 40;
    },

    convertTickToRoundSeconds(startTick, endTick, eventTick, ticksPerSecond) {
      var ticksPerSecond = ticksPerSecond == null ? 128 : ticksPerSecond;

      var ms = ((eventTick - startTick) * 1000) / ticksPerSecond;

      var minutes = Math.floor(ms / 60000);
      var seconds = ((ms % 60000) / 1000).toFixed(0);
      return minutes + ":" + (seconds < 10 ? "0" : "") + seconds;
    },

    openTagsModal(type, id, tagList) {
      // this.favorite.favorable_type = type;
      // if (type == "Match") this.favorite.favorable_id = this.$route.params.id;
      // else if (type == "MatchRound") this.favorite.favorable_id = id;
      this.round_to_tag = id;

      this.tag_list = "";
      if (tagList.length > 0) {
        this.tags = tagList.map(t => {
          return { text: t };
        });
      } else {
        this.tags = [];
      }

      this.$refs.modalTags.show();
    },

    handleShareSubmit() {
      this.$http
        .post(`matches/${this.$route.params.id}/share.json`, this.share)
        .then(response => {
          this.$notify({
            group: "all",
            text: "Convite enviado",
            type: "success",
            duration: -1000
          });
          this.$refs.modalShare.hide();
        })
        .catch(error => {
          // console.log(error);
        });
    },

    destroyMatch() {
      if (
        prompt("AVISO: Tem certeza que deseja apagar?\nDigite sim") == "sim"
      ) {
        this.$http
          .delete(`matches/${this.$route.params.id}.json`)
          .then(response => {
            this.$notify({
              group: "all",
              text: "Partida apagada com sucesso",
              type: "success",
              duration: -1000
            });
            this.$router.push("/");
          });
      }
    },

    handleTagsSubmit() {
      this.$http
        .patch(`match_rounds/${this.round_to_tag}.json`, {
          match: {
            tag_list: this.tags
              .map(t => {
                return t.text;
              })
              .join(",")
          }
        })
        .then(response => {
          this.initialize();
          this.$refs.modalTags.hide();
        });
    },

    handleEditSubmit() {
      this.$http
        .patch(`matches/${this.$route.params.id}.json`, {
          match: {
            name: this.match.name,
            description: this.match.description,
            team_a_id: this.match.team_a_id,
            team_b_id: this.match.team_b_id,
            unique_code: this.match.unique_code,
            is_public: this.match.is_public
          }
        })
        .then(response => {
          this.initialize();
          this.$refs.modalEditMatch.hide();
        });
    },

    addTag(tagName) {
      this.tags.push({ text: tagName });
    },

  },
  mounted() {
    this.initialize();
  },
  watch: {
    "$route.params.id": function(id) {
      this.initialize();
    }
  },
  components: {
    VueTagsInput,
    CollectionModal,
    LineChart
  }
};
// Header
</script>

<style lang="sass" scoped>
.match-header
  .header-content
    padding: 20px
    margin: 10px 0
    color: white
  .header-sidebar
    padding: 20px
    .btn
      position: relative
      text-align: left
      font-weight: 700
      font-size: 12px
      text-transform: uppercase
      background: transparent
      padding: 3px 6px 3px 32px
      border: 1px solid #ccc
      opacity: .5
      outline: 0
      transition: all .2s
      &:hover
        opacity: 1
        color: white
        background: $danger
      i
        border-right: 1px solid #ddd
        position: absolute
        top: 0
        left: 3px
        width: 22px
        height: 24px
        display: flex
        justify-content: center
        align-items: center

a
  transition: all .3s
  &:hover
    text-decoration: none
    font-weight: bold
    color: $link
.table-scoreboard
  table.table
    td:first-child
      width: 70%
.rounds-bar-horizontal
  width: 100%
  display: flex
  padding: 10px
  justify-content: center

  margin: 20px 0
  background: #f1f1f1
  padding: 20px 0

  .legend-round
    padding: 2px 5px
    text-align: center
    font-weight: bold
    margin-right: 6px
    opacity: .4
    .line
      height: 20px
      color: blue
      &:first-of-type
        color: red
    .number
      color: #eee
  .round-column
    // background: rgba(255, 255, 255, .4)
    .round-cont
      width: 24px
      margin-right: 6px
      text-align: center
      display: inline-block
      transition: all .1s linear
      svg
        opacity: .4
      .line
        height: 20px
      .number
        text-align: center
        font-weight: bold
        color: #999
      
      &:hover
        background: #fcfcfc
        box-shadow: 0 0 0 1px #dedede
        transform: scale(1.1) translateY(-3px)
        text-decoration: none
        padding: 3px 2px
        svg
          opacity: 1
        .number
          color: #111

    &.swap
      width: 65px
      display: flex
      .swap-round
        display: inline-block
        text-align: center
        margin: 0 8px
        color: #333
        opacity: .8
        transform: translateY(-5px)
        .number
          font-weight: bold

    
.timeline-column
  width: 50%
  overflow: hidden
  .timeline-container
    position: relative
    width: 400px
    height: 4px
    background: #d1d1d1
    top: 20px
  
    .timeline-events
      position: relative
      top: -10px
      .event
        position: absolute
        span
          display: block
          color: #000
          &.player_death
            
          &.bomb_planted
.team-column
  display: flex
  // justify-content: space-around
  div
    // font-size: 18px
    font-weight: bold
    &:first-child
      margin-right: 5px
    &:last-child
      margin-left: 5px            
</style>
